<template>
  <div class="Footer">
    <div class="Footer-con" >
      <div class="box-center flex"   style="height:100%" >
        <div class="Footer-con-left">
          <!-- <p style="color:var(--footer-color)">Guangdong Aofei New Energy</p>
          <div>Aofei New Energy SG Pte Ltd</div>
          <div></div> -->
          <p style="color:var(--footer-color)">{{$t('solutions.footer.title1')}}</p>
          <div><i class="icon el-icon-time"></i>09:00-18:00 </div>
          <div><i class="icon el-icon-phone-outline"></i>+86 4008869117 / +65 9876 7722</div>
          <div><i class="icon el-icon-message"></i>export@aofeine.com / contact@aofeine.com.sg</div>
        </div>
        <div class="Footer-con-center">
          <p style="color:var(--footer-color)">{{$t('solutions.footer.title2')}}</p>
          <div style="fontSize:16px">{{$t('solutions.footer.cont1')}}</div>
          <div style="margin-top:30px">{{$t('solutions.footer.cont2')}}</div>
        </div>
        <div class="Footer-con-right">
          <p style="color:var(--footer-color)"></p>
          <div style="fontSize:16px">{{$t('solutions.footer.cont3')}}</div>
          <div style="margin-top:30px">{{$t('solutions.footer.cont4')}}</div>
          <!-- <p style="color:var(--footer-color)">WORKING TIME</p>
          <div><i class="icon el-icon-time"></i>09:00-18:00 </div>
          <div><i class="icon el-icon-phone-outline"></i>+86 4008869117</div>
          <div><i class="icon el-icon-message"></i>export@aofeine.com</div> -->
        </div>
      </div>
    </div>
    <div class="Footer-copy">© Copyright 2024 广东奥飞新能源有限公司 | All rights reserved <el-link href="http://beian.miit.gov.cn" target="_blank" style="line-height: 20px;">粤ICP备2022059779号-1</el-link></div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
}
</script>

<style lang="scss" scoped>
.Footer{
  color: #ffffff;
  border-top: 5px solid var(--theme-color);
  .Footer-con{
    background: #000000;
    height: 270px;
    .Footer-con-left{
      width: 32%;
      height: 300;
      border-right: var(--footer-color) 1px dotted;
    }
    .Footer-con-center{
      width: 32%;
    }
    .Footer-con-right{
      width: 24%;
      padding-top: 45px;
      div{
        margin-bottom: 13px;
      }
    }
    .box-center >div{
      p{
        font-family: 'Noto Sans SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 26px;
        margin-top: 37px;
      }
      div{
        font-size: 12px;
        line-height: 26px;
        img{
          margin-right: 15px;
          vertical-align: middle;
        }
      }
    }
  }
  .Footer-copy{
    height: 58px;
    line-height: 58px;
    mix-blend-mode: normal;
    background: #000000;
    text-align: center;
    color: #808080;
    font-size: 14px;
    border-top: #808080 1px solid;
  }
}
.icon {
  font-size: 16px;
  padding-right: 15px;
  color: var(--footer-color);
}

@media screen and (max-width: 980px) {
  .Footer {
    overflow:hidden;
    
  }
  .Footer .Footer-con {
    padding: 0 5%;
  }
  .Footer .Footer-con .Footer-con-left {
    display: none;
  }
  .Footer .Footer-con .Footer-con-center {
    width: 100%;
  }
  .Footer .Footer-con .Footer-con-right {
    width: 100%;
  }
  .Footer .Footer-copy {
    padding-top: 10px;
    line-height: 20px;
  }
}

</style>
